<template>
    <div>
        <div class="m-2">
            <!-- Table Top -->
            <b-row>

                <b-col cols="12" v-if="checkPermission($route.name, 'Export Yearly Report') || checkPermission($route.name, 'Export Yearly Detailed Report') || checkPermission($route.name, 'Enable Update')"
                >
                    <div class="d-flex align-items-center justify-content-end mobile-view mb-1">
                        <ExportYearlyExcelReport 
                            v-bind:siteData="siteData ? siteData._id : null" 
                            v-bind:site_name="siteData ? siteData.site_name : null" 
                            v-bind:group="groupArray && groupArray.length > 0 ? groupArray : []"
                            v-if="checkPermission($route.name, 'Export Yearly Report')"
                        />
                        
                        <ExportYearlyExcelDetailedReport 
                            v-bind:siteData="siteData ? siteData._id : null" 
                            v-bind:site_name="siteData ? siteData.site_name : null"
                            v-bind:group="groupArray && groupArray.length > 0 ? groupArray : []"
                            v-if="checkPermission($route.name, 'Export Yearly Detailed Report')"
                        />
                        <!-- <b-button
							:variant="update == 'disabled' ? 'primary' : 'warning'"
							class="ml-1 mobile-margin-bottom"
							@click="changeUpdate"
                            v-if="checkPermission($route.name, 'Enable Update')"
						>
							<span class="text-nowrap" v-if="update == 'disabled'">Enable Update</span>
							<span class="text-nowrap" v-if="update == 'enabled'">Disable Update</span>
						</b-button> -->
                    </div>
                </b-col>

                <!-- Per Page -->
                <b-col cols="12" md="3" class="d-flex align-items-center justify-content-start mb-1 mb-md-0 mobile_tab_max_width_flex mobile_margin_bottom_1rem">
                    <label>Show</label>
                    <v-select
                        v-model="perPage"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="perPageOptions"
                        :clearable="false"
                        class="per-page-selector d-inline-block mx-50"
                        @input="filterTable"
                    />
                    <label>entries</label>
                </b-col>

                <!-- Search -->
                <b-col cols="12" md="9" class="mobile_tab_max_width_flex">
                    <div class="d-flex align-items-center justify-content-end mobile-view mr_1_last_child mobile_tab_display_block">
                        <b-form-input
                            v-model="searchQuery"
                            class="d-inline-block mr-1 mobile-margin-bottom mobile-margin-input mobile_margin_bottom_1rem"
                            placeholder="Search..."
                            @input="filterTable"
                        />

                        <b-button
                            :disabled="(siteData != null && siteData._id != 'all-site' && items.length > 0)  ? false:true"
                            variant="success"
                            class="mr-1 mobile-margin-bottom"
                            @click="openPDFPopUp()"
                            v-if="checkPermission($route.name, 'Pdf')"
                        >
                            <span class="text-nowrap">PDF</span>
                        </b-button>

                        <b-button
                            :disabled="(siteData != null && siteData._id != 'all-site' && items.length > 0)  ? false:true"
                            variant="secondary"
                            class="mr-1 mobile-margin-bottom"
                            @click="download('excel')"
                            v-if="checkPermission($route.name, 'Excel')"
                        >
                            <span class="text-nowrap">Excel</span>
                        </b-button>

                        <b-button
                            :disabled="!(selected.length > 0)"
                            variant="danger"
                            class="mr-1 mobile-margin-bottom"
                            @click="changeMultipleStatus('deleted')"
                            v-if="checkPermission($route.name, 'Delete')"
                        >
                            <span class="text-nowrap">Delete</span>
                        </b-button>

                        <b-button
                            variant="warning"
                            :disabled="siteData != null && siteData._id != 'all-site'  ? false:true"
                            class="mr-1 mobile-margin-bottom"
                            @click="openModel"
                            v-if="checkPermission($route.name, 'Export Group')"
                        >
                            <span class="text-nowrap">Export Group</span>
                        </b-button>

                        <b-button
                            :disabled="siteData != null && siteData._id != 'all-site'  ? false:true"
                            variant="info"
                            class="mr-1 mobile-margin-bottom"
                            @click="zipDownload()"
                            v-if="checkPermission($route.name, 'Zip Download')"
                        >
                            <span class="text-nowrap">Zip Download</span>
                        </b-button>

                    </div>
                </b-col>

            </b-row>
        </div>
        <b-table sticky-header
            ref="refUserListTable"
            class="position-relative  mTable"
            :items="dataList"
            responsive
            :fields="tableColumns"
            primary-key="id"
            :sort-by.sync="sortBy"
            show-empty
            empty-text="No matching records found"
            :sort-desc.sync="isSortDirDesc"
            :class="items.length <= 10 ? 'remove_overflow' : 'add_overflow'"
        >
            <!-- Column: User -->
            <template #head(checkbox)="items">
                <b-form-checkbox class="custom-control-warning p-0" v-model="selectAll" @change="selectall"/>
            </template>

            <template #cell(checkbox)="items">				
                <b-form-checkbox v-model="selected" :value="items.item._id" class="custom-control-warning my-1" />				
            </template>				

            <template #cell(info)="items">             
                <feather-icon
                  :icon="items.detailsShowing ? 'EyeIcon' : 'EyeOffIcon' "
                  @click="items.toggleDetails"
                />
            </template>

            <template #cell(site)="items">				
                <span class="wordBreak">{{ items.item.site.site_name | capitalize}}</span>				
            </template>

            <template #cell(locations)="items">
                <span class="wordBreak" v-for="item in items.item.locations" :key="item._id">{{item.name | capitalize}}</span>
            </template>

            <template #cell(type)="items">
				<span class="wordBreak">{{ items.item.type | capitalize}}</span>
			</template>

            <template #cell(unique_id)="items">				
                <span  @click="items.toggleDetails" class="wordBreak">{{ items.item.unique_id}}</span>				
            </template>

            <template #cell(title)="items">				
                <span @click="items.toggleDetails" class="wordBreak">{{ items.item.title | capitalize}}</span>				
            </template>

            <template #cell(crew)="items">
                <span class="wordBreak">{{ items.item.crew | crewArray }}</span>				
            </template>

            <template #cell(feedback)="items">				
                <!-- <span class="wordBreak" v-if="items.item.feedback_from == 'web'">Web</span> -->
                <span class="wordBreak" v-if="items.item.feedback_from == 'web'">QR Code</span>
                <span class="wordBreak" v-else-if="items.item.feedback_from == 'app'">App</span>
                <span class="wordBreak" v-else>{{ 'N/A'  }}</span>
            </template>

            <template #cell(start)="items">
                <!-- <span v-if="siteData" class="wordBreak">{{ sitedateTime(items.item.start,siteData.pdf_date_format,'' )}}</span> -->
                <span @click="items.toggleDetails" v-if="siteData" class="wordBreak">{{ sitedateTime(items.item.start,date_format,'' )}}</span>
            </template>

            <template #cell(end)="items">
                <!-- <span v-if="siteData" class="wordBreak">{{ sitedateTime(items.item.end,siteData.pdf_date_format,'' )}}</span> -->
                <span v-if="siteData" class="wordBreak">{{ sitedateTime(items.item.end,date_format,'' )}}</span>
            </template>

            <template #cell(completed_by)="items">
                <span @click="items.toggleDetails" class="wordBreak" v-if="items.item.completed_by">{{ items.item.completed_by.full_name | capitalize}}</span>
                <span @click="items.toggleDetails" v-if="items.item.completed_by == null" class="wordBreak">N/A</span>
            </template>
            
            <template #cell(status)="items">
                <b-badge pill :variant="variantColor(items.item.status)" class="text-capitalize">
                    {{ items.item.status | capitalize}}
                </b-badge>				
            </template>

            <template #cell(task_status)="items">
                <b-badge @click="items.toggleDetails" pill :variant="variantProgressColor(items.item.task_status)" class="text-capitalize mb-2">
                    {{ items.item.task_status | capitalize}}
                </b-badge>				
            </template>

            <!-- <template #cell(actual_started)="items">    -->
                
                <!-- <span @click="items.toggleDetails" class="wordBreak">{{ sitedateTime2(items.item.actual_started,siteData.pdf_date_format,siteData.pdf_time_format )}}</span> -->

				<!-- <span @click="items.toggleDetails" class="wordBreak" v-if="update == 'disabled'">{{ sitedateTime2(items.item.actual_started,siteData.pdf_date_format,siteData.pdf_time_format )}}</span>
				
				<date-picker 
					v-if="update == 'enabled'"
					v-model="items.item.actual_started" 
					format="YYYY-MM-DD HH:mm"
					type="datetime"
					placeholder="Select"
					valueType="format"
					lang="en"
					:class="$store.state.appConfig.layout.skin == 'dark' ? 'darkDatePicker' : 'lightDatePicker'"
					@confirm = "updateTime(items.item._id,items.item.actual_started,'actual_started')"
					@change = "updateTime(items.item._id,items.item.actual_started,'actual_started')"
					:clearable=false
					:confirm=true
				>				
				</date-picker> -->
			<!-- </template> -->

			<template #cell(actual_end)="items">

                <span @click="items.toggleDetails" class="wordBreak">{{ sitedateTime2(items.item.actual_end,siteData.pdf_date_format,siteData.pdf_time_format )}}</span>

				<!-- <span @click="items.toggleDetails" class="wordBreak" v-if="update == 'disabled'">{{ sitedateTime2(items.item.actual_end,siteData.pdf_date_format,siteData.pdf_time_format )}}</span>
				
				<date-picker 
					v-if="update == 'enabled'"
						v-model="items.item.actual_end" 
						format="YYYY-MM-DD HH:mm"
						type="datetime"
						placeholder="Select"
						valueType="format"
						lang="en"
						:class="$store.state.appConfig.layout.skin == 'dark' ? 'darkDatePicker' : 'lightDatePicker'"
						@confirm = "updateTime(items.item._id,items.item.actual_end,'actual_end')"
						@change = "updateTime(items.item._id,items.item.actual_end,'actual_end')"
						:clearable=false
						:confirm=true
					>
				</date-picker> -->
			
			</template>

            <template #cell(created_at)="items">				
                <!-- <span v-if="siteData" class="wordBreak">{{sitedateTime(items.item.created_at,siteData.pdf_date_format,siteData.pdf_time_format)}}</span> -->
                <span @click="items.toggleDetails" v-if="siteData" class="wordBreak">{{sitedateTime(items.item.created_at,date_format,time_format)}}</span>
            </template>
            
            <!-- <template #cell(start)="items">				
                <span @click="items.toggleDetails" v-if="siteData" class="wordBreak">{{sitedateTime(items.item.start,date_format,'')}}</span>
            </template>
            
            <template #cell(end)="items">				
                <span @click="items.toggleDetails" v-if="siteData" class="wordBreak">{{sitedateTime(items.item.end,date_format,'')}}</span>
            </template> -->

            <template #cell(time_taken)="items">                
                <span class="wordBreak">{{items.item.time_taken | capitalize}}</span>
            </template>

            <template #cell(actions)="items">
                <div>
                    <b-link v-if="(checkPermission($route.name, 'Download Report')) && (items.item.task_status == 'closed' && items.item.pdf != null)" @click="openPdf(items.item.pdf)" v-b-tooltip.hover.v-warning title="Download Report" variant="outline-warning">
                        <feather-icon icon="DownloadIcon" class="mediumSize mr-1" />	
                    </b-link>

                    <b-link v-if="(checkPermission($route.name, 'Re Generate Report')) && (items.item.task_status == 'closed')" @click="reGenerateReport(items.item._id)" v-b-tooltip.hover.v-warning title="Re Generate Report" variant="outline-warning">
                        <feather-icon icon="RefreshCwIcon" class="mediumSize mr-1" />
                    </b-link>

                    <b-link v-if="(items.item.task_status == 'closed') && checkPermission($route.name, 'Modify Completion Time')"  v-b-tooltip.hover.v-warning title="Modify Completion Time" variant="outline-warning" @click="openTimePopup(items.item)">
                        <feather-icon icon="ClockIcon" class="mediumSize mr-1" />
                    </b-link>

                </div>
            </template>

            <template #row-details="items">
                <div class="p-1 px-2 fbDetail mb-1">
                    <b-row>

                        <!-- <b-col cols="12" xl="12">
                            <table class="mt-2 mt-xl-0 w-100">
                                <tr class="mb-1">
                                    <th class="pb-50" style="width: 16%">
                                      <div class="d-flex align-items-center mr-2">
                                        
                                        <div class="ml-1">
                                          <h6 class="mb-0 smallFonts">
                                            Project Site 
                                          </h6>
                                        
                                        </div>
                                      </div>
                                    </th>
                                    <td class="pb-50 mediumFonts" style="width: 17%;padding-top: 8px;">
                                      {{ items.item.site.site_name | capitalize}}
                                    </td>

                                    <th class="pb-50" style="width: 16%">
                                      <div class="d-flex align-items-center mr-2">
                                        
                                        <div class="ml-1">
                                          <h6 class="mb-0 smallFonts">
                                            Location 
                                          </h6>
                                        
                                        </div>
                                      </div>
                                    </th>
                                    <td class="pb-50 mediumFonts" style="width: 17%;padding-top: 8px;">
                                      <span class="wordBreak" v-for="item in items.item.locations" :key="item._id">{{item.name | capitalize}}</span>
                                    </td>

                                    <th class="pb-50" style="width: 16%">
                                      <div class="d-flex align-items-center mr-2">
                                        
                                        <div class="ml-1">
                                          <h6 class="mb-0 smallFonts">
                                            Feedback From 
                                          </h6>
                                        
                                        </div>
                                      </div>
                                    </th>
                                    <td class="pb-50 mediumFonts" style="width: 17%;padding-top: 8px;">
                                        <span class="wordBreak" v-if="items.item.feedback_from == 'web'">QR Code</span>
                                        <span class="wordBreak" v-else-if="items.item.feedback_from == 'app'">Feedback Panel</span>
                                        <span class="wordBreak" v-else>{{ 'N/A'  }}</span>
                                    </td>
                                </tr>
                                <tr class="mb-1">
                                    <th class="pb-50" style="width: 16%">
                                      <div class="d-flex align-items-center mr-2">
                                        
                                        <div class="ml-1">
                                          <h6 class="mb-0 smallFonts">
                                            Trigger Time 
                                          </h6>
                                        
                                        </div>
                                      </div>
                                    </th>
                                    <td class="pb-50 mediumFonts" style="width: 17%;padding-top: 8px;">
                                        <span v-if="siteData" class="wordBreak">{{sitedateTime(items.item.created_at,date_format,time_format)}}</span>
                                    </td>

                                    <th class="pb-50" style="width: 16%">
                                      <div class="d-flex align-items-center mr-2">
                                        
                                        <div class="ml-1">
                                          <h6 class="mb-0 smallFonts">
                                            Actual Time 
                                          </h6>
                                        
                                        </div>
                                      </div>
                                    </th>
                                    <td class="pb-50 mediumFonts" style="width: 17%;padding-top: 8px;">
                                        <span v-if="siteData" class="wordBreak">{{sitedateTime2(items.item.actual_started,date_format,time_format)}}</span>
                                    </td>

                                    <th class="pb-50" style="width: 16%">
                                      <div class="d-flex align-items-center mr-2">
                                        
                                        <div class="ml-1">
                                          <h6 class="mb-0 smallFonts">
                                            Completed Time 
                                          </h6>
                                        
                                        </div>
                                      </div>
                                    </th>
                                    <td class="pb-50 mediumFonts" style="width: 17%;padding-top: 8px;">
                                        <span v-if="siteData" class="wordBreak">{{sitedateTime2(items.item.actual_end,date_format,time_format)}}</span>
                                    </td>
                                </tr>
                                <tr class="mb-1">
                                    <th class="pb-50" style="width: 16%">
                                      <div class="d-flex align-items-center mr-2">
                                        
                                        <div class="ml-1">
                                          <h6 class="mb-0 smallFonts">
                                            Crew 
                                          </h6>
                                        
                                        </div>
                                      </div>
                                    </th>
                                    <td class="pb-50 mediumFonts" style="width: 17%;padding-top: 8px;">
                                        <span class="wordBreak">{{ items.item.crew | crewArray }}</span>
                                    </td>

                                    <th class="pb-50" style="width: 16%">
                                      <div class="d-flex align-items-center mr-2">
                                        
                                        <div class="ml-1">
                                          <h6 class="mb-0 smallFonts">
                                            Completed By 
                                          </h6>
                                        
                                        </div>
                                      </div>
                                    </th>
                                    <td class="pb-50 mediumFonts" style="width: 17%;padding-top: 8px;">
                                        <span class="wordBreak" v-if="items.item.completed_by">{{ items.item.completed_by.full_name | capitalize}}</span>
                                        <span v-if="items.item.completed_by == null" class="wordBreak">N/A</span>
                                    </td>

                                    <th class="pb-50" style="width: 16%">
                                      <div class="d-flex align-items-center mr-2">
                                        
                                        <div class="ml-1">
                                          <h6 class="mb-0 smallFonts">
                                            Time Taken 
                                          </h6>
                                        
                                        </div>
                                      </div>
                                    </th>
                                    <td class="pb-50 mediumFonts" style="width: 17%;padding-top: 8px;">
                                        <span class="wordBreak">{{ items.item.total_time | capitalize }}</span>
                                    </td>
                                </tr>
                                <tr class="mb-1">

                                    <th class="pb-50" style="width: 16%">
                                      <b-button
                                          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                                          variant="outline-secondary"
                                          @click="items.toggleDetails"
                                          size="sm"
                                          class="ml-1"
                                        >
                                          Hide Detail
                                        </b-button>
                                    </th>
                                    <td class="pb-50 mediumFonts" style="width: 17%;padding-top: 8px;">
                                        
                                    </td>
                                    <th class="pb-50" style="width: 16%">
                                    </th>
                                    <td class="pb-50 mediumFonts" style="width: 17%;padding-top: 8px;">
                                        
                                    </td>
                                    <th class="pb-50" style="width: 16%">
                                    </th>
                                    <td class="pb-50 mediumFonts" style="width: 17%;padding-top: 8px;">
                                        
                                    </td>


                                    

                                </tr>
                            </table>
                        </b-col> -->

                        <b-col cols="12" xl="12">
                            <table class="mt-2 mt-xl-0 w-100">
                                <tr class="mb-1">
                                    <th class="pb-50" style="width: 16%">
                                      <div class="d-flex align-items-center">
                                        
                                        <div class="ml-1">
                                          <h6 class="mb-0 mediumFonts">
                                            Project Site 
                                          </h6>
                                        <!--  <small>Monthly Sales</small> -->
                                        </div>
                                      </div>
                                    </th>
                                    <td class="pb-50 mediumFonts" style="width: 17%;padding-top: 8px;">
                                      {{ items.item.site.site_name | capitalize}}
                                    </td>

                                    <th class="pb-50" style="width: 16%">
                                      <div class="d-flex align-items-center">
                                        
                                        <div class="ml-1">
                                          <h6 class="mb-0 mediumFonts">
                                            Location 
                                          </h6>
                                        <!--  <small>Monthly Sales</small> -->
                                        </div>
                                      </div>
                                    </th>
                                    <td class="pb-50 mediumFonts" style="width: 17%;padding-top: 8px;">
                                      <span class="wordBreak" v-for="item in items.item.locations" :key="item._id">{{item.name | capitalize}}</span>
                                    </td>
                                
                                    <th class="pb-50" style="width: 16%">
                                      <div class="d-flex align-items-center">
                                        
                                        <div class="ml-1">
                                          <h6 class="mb-0 mediumFonts">
                                            Created At
                                          </h6>
                                        <!--  <small>Monthly Sales</small> -->
                                        </div>
                                      </div>
                                    </th>
                                    <td class="pb-50 mediumFonts" style="width: 17%;padding-top: 8px;">
                                      {{ sitedateTime(items.item.created_at,date_format,time_format) }}
                                    </td>
                                </tr>

                                <tr class="mb-1">
                                    <th class="pb-50" style="width: 16%">
                                      <div class="d-flex align-items-center">
                                        
                                        <div class="ml-1">
                                          <h6 class="mb-0 mediumFonts">
                                            End Date 
                                          </h6>
                                        <!--  <small>Monthly Sales</small> -->
                                        </div>
                                      </div>
                                    </th>
                                    <td class="pb-50 mediumFonts" style="width: 17%;padding-top: 8px;">
                                      {{ sitedateTime(items.item.end,date_format,'' ) }}
                                    </td>

                                    <th class="pb-50" style="width: 16%">
                                      <div class="d-flex align-items-center">
                                        
                                        <div class="ml-1">
                                          <h6 class="mb-0 mediumFonts">
                                            Crew 
                                          </h6>
                                        <!--  <small>Monthly Sales</small> -->
                                        </div>
                                      </div>
                                    </th>
                                    <td class="pb-50 mediumFonts" style="width: 17%;padding-top: 8px;">
                                        <span class="wordBreak">{{ items.item.crew | crewArray }}</span>
                                    </td>
                                    
                                    <th class="pb-50" style="width: 16%">
                                      <div class="d-flex align-items-center">
                                        
                                        <div class="ml-1">
                                          <h6 class="mb-0 mediumFonts">
                                            Completed By 
                                          </h6>
                                        <!--  <small>Monthly Sales</small> -->
                                        </div>
                                      </div>
                                    </th>
                                    <td class="pb-50 mediumFonts" style="width: 17%;padding-top: 8px;">
                                        <span class="wordBreak" v-if="items.item.completed_by">{{ items.item.completed_by.full_name | capitalize}}</span>
                                        <span v-if="items.item.completed_by == null" class="wordBreak">N/A</span>
                                    </td>

                                </tr>
                                
                                <tr class="mb-1">

                                    <th class="pb-50" style="width: 16%">
                                      <div class="d-flex align-items-center">
                                        
                                        <div class="ml-1">
                                          <h6 class="mb-0 mediumFonts">
                                            Time Taken 
                                          </h6>
                                        <!--  <small>Monthly Sales</small> -->
                                        </div>
                                      </div>
                                    </th>
                                    <td class="pb-50 mediumFonts" style="width: 17%;padding-top: 8px;">
                                        <span class="wordBreak">{{ items.item.total_time | capitalize }}</span>
                                    </td>

                                    <th class="pb-50" style="width: 16%">
                                      <div class="d-flex align-items-center">
                                        
                                        <div class="ml-1">
                                          <h6 class="mb-0 mediumFonts">
                                            Group 
                                          </h6>
                                        <!--  <small>Monthly Sales</small> -->
                                        </div>
                                      </div>
                                    </th>
                                    <td class="pb-50 mediumFonts" style="width: 17%;padding-top: 8px;">
                                        <span v-if="items.item.group.length > 0" class="wordBreak">{{ items.item.group.toString() }}</span>
                                        <span v-else class="wordBreak">N/A</span>
                                    </td>

                                    <th class="pb-50" style="width: 16%">
                                      <div class="d-flex align-items-center">
                                        
                                        <div class="ml-1">
                                          <h6 class="mb-0 mediumFonts">
                                            Frequency 
                                          </h6>
                                        <!--  <small>Monthly Sales</small> -->
                                        </div>
                                      </div>
                                    </th>
                                    <td class="pb-50 mediumFonts" style="width: 17%;padding-top: 8px;">
                                        <span v-if="items.item.type" class="wordBreak">{{ items.item.type | capitalize }}</span>
                                        <span v-else class="wordBreak">N/A</span>
                                    </td>
                                </tr>
                                <tr class="mb-1">
                                    
                                    <th class="pb-50" style="width: 16%">
                                      <div class="d-flex align-items-center">
                                        
                                        <div class="ml-1">
                                          <h6 class="mb-0 mediumFonts">
                                            Start Time
                                          </h6>
                                        </div>
                                      </div>
                                    </th>
                                    <td class="pb-50 mediumFonts" style="width: 17%;padding-top: 8px;">
										<span @click="items.toggleDetails" class="wordBreak">{{ sitedateTime2(items.item.actual_started,siteData.pdf_date_format,siteData.pdf_time_format )}}</span>
                                    </td>
                                </tr>
                                <tr class="mb-1">

                                    <th class="pb-50" style="width: 16%">
                                      <b-button
                                          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                                          variant="outline-secondary"
                                          @click="items.toggleDetails"
                                          size="sm"
                                          class="ml-1"
                                        >
                                          Hide Detail
                                        </b-button>
                                    </th>
                                    <td class="pb-50 mediumFonts" style="width: 17%;padding-top: 8px;">
                                        
                                    </td>
                                    <th class="pb-50" style="width: 16%">
                                    </th>
                                    <td class="pb-50 mediumFonts" style="width: 17%;padding-top: 8px;">
                                        
                                    </td>
                                    <th class="pb-50" style="width: 16%">
                                    </th>
                                    <td class="pb-50 mediumFonts" style="width: 17%;padding-top: 8px;">
                                        
                                    </td>
                                </tr>
                            </table>
                        </b-col>

                        
                    </b-row>
                </div>

            </template>

        </b-table>

        <div class="mx-2 mb-2">
            <b-row>

                <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
                    <span class="text-muted">Showing {{ from }} to {{ to }} of {{ totalRecords }}</span>
                </b-col>

                <!-- Pagination -->
                <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end pagination_fix">
                    <b-pagination
                        v-model="currentPage"
                        :total-rows="totalRecords"
                        :per-page="perPage"
                        first-number
                        last-number
                        class="mb-0 mt-1 mt-sm-0"
                        prev-class="prev-item"
                        next-class="next-item"
                        @input="filterTable"
                    >
                        <template #prev-text>
                            <feather-icon icon="ChevronLeftIcon" size="18"/>
                        </template>

                        <template #next-text>
                            <feather-icon icon="ChevronRightIcon" size="18"/>
                        </template>
                    </b-pagination>
                </b-col>

            </b-row>
        </div>

        <b-modal
            id="pdf_export"
            ref="pdf_export"
            cancel-variant="outline-secondary"
            ok-variant="outline-warning"
            ok-title="Submit"
            cancel-title="Close"
            centered
            title="Generate PDF"
            @ok="validatePDF"
            no-close-on-backdrop
        >
            <b-alert v-model="popshowDismissibleAlert" variant="danger" dismissible class="mb-1">
                <div class="alert-body"> {{error_message}}</div>
            </b-alert>

            <b-form>
                <b-row>
                    <b-col md="12">
                        <div class="demo-inline-spacing">
                            <b-form-radio class="mb-1" name="some-radios" value="single" v-model="pdf_type"> Single Page </b-form-radio>
                            <b-form-radio class="mb-1" name="some-radios" value="multiple" v-model="pdf_type"> Multiple Pages </b-form-radio>
                        </div>
                    </b-col>
                </b-row>
            </b-form>

        </b-modal>

        <b-modal
            id="group_export"
            ref="group_export"
            cancel-variant="outline-secondary"
            ok-variant="outline-warning"
            ok-title="Submit"
            cancel-title="Close"
            centered
            title="Export Group"
            @ok="exportGroup"
            no-close-on-backdrop
        >
            <b-alert v-model="popshowDismissibleAlert" variant="danger" dismissible class="mb-1">
                <div class="alert-body"> {{error_message}} </div>
            </b-alert>

            <b-form>
                <b-row>
                    <b-col md="12">
                        <div class="demo-inline-spacing">
                            <b-form-radio
                                class="mb-1"
                                name="some-radios"
                                value="selected"
                                v-model="group_selected"
                                @change="taskgroup = []"
                            >
                                Select
                            </b-form-radio>

                            <b-form-radio
                                class="mb-1"                            
                                name="some-radios"
                                value="all"
                                v-model="group_selected"
                                @change="taskgroup = []"
                            >
                                All
                            </b-form-radio>
                        </div>
                    </b-col>

                    <b-col md="12" v-if="group_selected == 'selected'">
                        <b-form-group label="Group" class="required">
                            <v-select
                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                :options="groupArray"
                                class="w-100"
                                placeholder="Select Export Group"
                                multiple
                                v-model="taskgroup"
                                :close-on-select=false
                            :clear-on-select=false
                            />
                        </b-form-group>
                    </b-col>

                    <b-col md="12">
                        <b-form-group label="Date" class="required">
                            <b-form-datepicker                                
                                v-model="taskdate"
                                :date-format-options="{ day: 'numeric', month: 'short',year: 'numeric' }"
                                locale="en"
                            />
                        </b-form-group>
                    </b-col>                    

                </b-row>
            </b-form>
        </b-modal>


        <b-modal
            id="modify_time"
            ref="modify_time"
            cancel-variant="outline-secondary"
            ok-variant="outline-warning"
            ok-title="Submit"
            cancel-title="Close"
            centered
            :title="'Modify Completion Time ('+ form.unique_id + ' )'"
            @ok="updateCompletionTime"
            no-close-on-backdrop
            size="lg"
        >
            <b-alert v-model="popshowDismissibleAlert" variant="danger" dismissible class="mb-1">
                <div class="alert-body"> {{error_message}}</div>
            </b-alert>

            <b-form>
                <b-row>
                    <b-col md="4">

                        <b-form-group
                          label="Start Time"
                          class="required mb-1"
                        >

                            <date-picker 
                                
                                v-model="form.actual_started" 
                                format="YYYY-MM-DD HH:mm"
                                type="datetime"
                                placeholder="Select"
                                valueType="format"
                                lang="en"
                                :class="$store.state.appConfig.layout.skin == 'dark' ? 'darkDatePicker' : 'lightDatePicker'"
                                :clearable=false
                                :confirm=true
                                :disabled-date="disabledDate"
                                :disabled-time="disabledTime"
                            >
                            </date-picker>
                        </b-form-group>
                        
                    </b-col>

                    <b-col md="4">

                        <b-form-group
                          label="Completed Time"
                          class="required mb-1"
                        >

                            <date-picker 
                                
                                v-model="form.actual_end" 
                                format="YYYY-MM-DD HH:mm"
                                type="datetime"
                                placeholder="Select"
                                valueType="format"
                                lang="en"
                                :class="$store.state.appConfig.layout.skin == 'dark' ? 'darkDatePicker' : 'lightDatePicker'"
                                :clearable=false
                                :confirm=true
                                :disabled-date="disabledDate"
                                :disabled-time="disabledTime"
                            >
                            </date-picker>
                        </b-form-group>
                        
                    </b-col>

                    <b-col md="4">

                        <b-form-group
                          label="Validate Time"
                          class="required mb-1"
                        >

                            <date-picker 
                                
                                v-model="form.closed_time" 
                                format="YYYY-MM-DD HH:mm"
                                type="datetime"
                                placeholder="Select"
                                valueType="format"
                                lang="en"
                                :class="$store.state.appConfig.layout.skin == 'dark' ? 'darkDatePicker' : 'lightDatePicker'"
                                :clearable=false
                                :confirm=true
                                :disabled-date="disabledDate"
                                :disabled-time="disabledTime"
                            >
                            </date-picker>
                        </b-form-group>
                        
                    </b-col>
                </b-row>
            </b-form>

        </b-modal>

    </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink, BBadge, BDropdown, 
  BDropdownItem, BPagination, BCardBody, BFormCheckbox, BAlert, BForm, BBreadcrumb, BFormDatepicker,
  BTabs, BTab, BFormSelect, BFormSelectOption, VBTooltip, BFormRadio, BFormGroup
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import { POST_API } from "../../../store/actions.type"
import moment from "moment-timezone";
import Completed from './Completed.vue';
import ExportYearlyExcelReport from './ExportYearlyExcelReport.vue';
import ExportYearlyExcelDetailedReport from './ExportYearlyExcelDetailedReport.vue';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import Ripple from 'vue-ripple-directive'

export default {
	components: {
		BCardBody, BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink, BBadge, BDropdown,
		BDropdownItem, BPagination, BFormCheckbox, vSelect, BAlert, BForm, BBreadcrumb, BFormDatepicker,
		BTabs, BTab, BFormSelect, BFormSelectOption, Completed, BFormRadio, BFormGroup,
        ExportYearlyExcelReport, ExportYearlyExcelDetailedReport, DatePicker
	},
	directives: {
		'b-tooltip': VBTooltip, Ripple
	},
    props: ['siteData', 'locations', 'crew_filter', 'status', 'start', 'end', 'frequencyData', 'group','assign_status'],
    watch: { 
        siteData: function(newVal, oldVal) { // watch it
            this.filterTable();
            this.updateDateFormat();
            this.getSiteGroup();
        },
        locations: function(newVal, oldVal) { // watch it
            this.filterTable();
        },
        crew_filter: function(newVal, oldVal) { // watch it
            this.filterTable();
        },
        status: function(newVal, oldVal) { // watch it
            this.filterTable();
        },
        start: function(newVal, oldVal) { // watch it
            this.filterTable();
        },
        end: function(newVal, oldVal) { // watch it
            this.filterTable();
        },
        frequencyData: function(newVal, oldVal) { // watch it
            this.filterTable();
        },
        group: function(newVal, oldVal) { // watch it
            this.filterTable();
        },
        assign_status: function(newVal, oldVal) { // watch it
            this.filterTable();
        },
    },

	data() {
		return {
            date_format : 'DD MMM YYYY',
			time_format : 'HH:mm',
            tableColumns : [
				/* { key: 'checkbox', label:'',sortable: false , thStyle:  {width: '2%',fontSize:'10px'}},
				{ key: 'unique_id', label: 'ID', sortable: true , thStyle:  {width: '8%',fontSize:'10px'}},
				{ key: 'locations', label: 'Location', sortable: false , thStyle:  {width: '7%',fontSize:'10px'}},
				{ key: 'site', label: 'Project Site', sortable: false , thStyle:  {width: '6%',fontSize:'10px'}},
				{ key: 'title', label: 'Task', sortable: true , thStyle:  {width: '9%',fontSize:'10px'}},
				{ key: 'type', label: 'Type', sortable: true , thStyle:  {width: '5%',fontSize:'10px'}},
				{ key: 'feedback', label: 'Feedback From', sortable: false , thStyle:  {width: '5%',fontSize:'10px'}},
				{ key: 'start', label: 'Start Date', sortable: true , thStyle:  {width: '5%',fontSize:'10px'}},
				{ key: 'task_status', label: 'Progress Status', sortable: true , thStyle:  {width: '6%',fontSize:'10px'}},
				{ key: 'crew', label: 'Crew', sortable: true , thStyle:  {width: '8%',fontSize:'10px'}},
				{ key: 'completed_by', label: 'Completed By', sortable: false , thStyle:  {width: '7%',fontSize:'10px'}},
				{ key: 'end', label: 'End Date', sortable: true , thStyle:  {width: '5%',fontSize:'10px'}},
				{ key: 'created_at', label: 'Trigger Time', sortable: true , thStyle:  {width: '6%',fontSize:'10px'}},
				{ key: 'actual_started', label: 'Actual Time', sortable: true , thStyle:  {width: '5%',fontSize:'10px'}},
            	{ key: 'actual_end', label: 'Completed Time', sortable: true , thStyle:  {width: '5%',fontSize:'10px'}},
            	{ key: 'total_time', label: 'Time Taken', sortable: true , thStyle:  {width: '5%',fontSize:'10px'}},
				{ key: 'actions', label: 'Action', sortable: false , thStyle:  {width: '11%',fontSize:'10px'}} */

                { key: 'checkbox', label:'',sortable: false , thStyle:  {width: '1%',fontSize:'11px'}},
                { key: 'info', label:'INFO',sortable: false , thStyle:  {width: '1%',fontSize:'11px'}},
                { key: 'unique_id', label: 'ID', sortable: true , thStyle:  {width: '30%',fontSize:'11px'}},
                
                { key: 'title', label: 'Task', sortable: true , thStyle:  {width: '22%',fontSize:'11px'}},
                
                { key: 'start', label: 'Start Date', sortable: true , thStyle:  {width: '14%',fontSize:'11px'}},

                // { key: 'actual_started', label: 'Start Time', sortable: true , thStyle:  {width: '14%',fontSize:'11px'}},

                { key: 'actual_end', label: 'Completed Time', sortable: true , thStyle:  {width: '14%',fontSize:'11px'}},
                
                { key: 'actions', label: 'Action', sortable: false , thStyle:  {width: '18%',fontSize:'11px'}}
            ],

			items:[],
			isSortDirDesc: true,
			perPageOptions: [10, 20, 50, 100],
			perPage : 10,
			sortBy : 'updated_at',
			webUrl: process.env.VUE_APP_SERVER_URL,
			totalRecords : 0,
			currentPage : 1,
			searchQuery:'',
			from:null,
			to:null,
			selectAll:false,
			selected:[],
			crew:[],
			yearData:[],
			sites:[],
			popshowDismissibleAlert:false,
			error_message:'',
            pdf_type:'single',
            
            dateFilter : 'periodic',
            taskdate:moment.tz('Asia/Singapore').subtract(1,'days').format('YYYY-MM-DD'),
            taskgroup:[],
            group_selected:'selected',
            groupDateType:'periodic',
            
            // group:null,
            groupArray:[],

            update:'disabled',

            form: {
                id:null,
                actual_started:null,
                actual_end:null,
                closed_time:null,
                unique_id:null,
                start_date:null,
            }
		}
	},

	methods : {
        changeUpdate(){
			if (this.update == 'disabled') {
				this.update = 'enabled';
			}else{
				this.update = 'disabled';
				this.$refs.refUserListTable.refresh();
			}
			return this.update;
		},

        updateTime(id,value,type){
			return this.$store.dispatch(POST_API, {
				data:{
					id:id,
					time:value,
					type:type
				},
				api: '/api/update-task-time'
			})
			.then(() => {
				if (this.$store.getters.containsErrors) {
					this.error_message = this.$store.getters.getErrors;
					this.showDismissibleAlert = true;
				} else {
					this.showDismissibleAlert = false;
					var data  = this.$store.getters.getResults.data;
					Swal.fire({
						position: 'center',
						icon: 'success',
						title: 'Record Updated Successfully',
						showConfirmButton: false,
						timer: 1000
					})					
				}
			});
		},

        updateDateFormat(){
			if (this.siteData != '') {
				this.sites.forEach(item => {
                    if (item._id == this.siteData) {
                        this.date_format = item.pdf_date_format;
                        this.time_format = item.pdf_time_format; 
                    }
				})
			}else{
				this.date_format = 'DD MMM YYYY';
				this.time_format = 'HH:mm';
			}
		},

		dataList(){
			return this.$store.dispatch(POST_API, {
				data:{
					page   	      : this.currentPage,
					keyword	      : this.searchQuery,
					rowsPerPage   : this.perPage,
					sortBy 	      : this.sortBy,
					sortDirection : this.isSortDirDesc,
					role   	      : this.$store.getters.currentUser.role,
					om_sites      : this.$store.getters.currentUser.om_sites,
					site   	      : this.siteData ? this.siteData._id : null,
					frequency     : this.frequencyData ? this.frequencyData.name :null,
					locations     : this.locations,
					crew   	      : this.crew_filter,
					start	      : moment(this.start).format('YYYY-MM-DD'),
					end    	      : moment(this.end).format('YYYY-MM-DD'),
					status 	      : this.status,
					tab_status    : 'closed',
					type   	  	  : this.type,
					report 		  : 'periodic',
					dateFilter    : this.dateFilter,
					group         : this.group,
                    assign_status : this.assign_status,
				},
				api: '/api/task-list-modal'
			})
			.then(() => {
				if (this.$store.getters.containsErrors) {
					this.error_message = this.$store.getters.getErrors;
				} else {
					this.items        = this.$store.getters.getResults.data.docs;
					this.totalRecords = this.$store.getters.getResults.data.total;
					this.perPage      = this.$store.getters.getResults.data.limit;
					this.from         = this.$store.getters.getResults.data.from;
					this.to           = this.$store.getters.getResults.data.to;

                    var data = {
                        open : this.$store.getters.getResults.data.open,
                        in_progress : this.$store.getters.getResults.data.in_progress,
                        validate : this.$store.getters.getResults.data.validate,
                        failed : this.$store.getters.getResults.data.failed
                    }

                    this.$emit('updateCount',data);
                    
					return this.items;
				}
			});
		},

		filterTable(){
            this.$refs.refUserListTable.refresh();
			this.selected = [];
			this.selectAll = false;
		},

		variantColor(status){
			if (status == 'active') {
				return 'success';
			}else{
				return 'primary';
			}
		},

		variantProgressColor(status){
			if (status == 'open') {
				return 'primary';
			}else if(status == 'in_progress'){
				return 'warning'
			}else if(status == 'validate'){
				return 'info'
			}else if(status == 'failed'){
				return 'danger'
			}else{
				return 'success';
			}
		},

        selectall(e){
			this.selected = [];		
			if (e) {
				var selected = [];

				this.items.forEach(function(item){
					selected.push(item._id);
				})
				this.selected = selected;
			}
		},

        changeMultipleStatus(status){
            if (this.selected.length > 0) {
                var msg = '';
                var msg2 = '';

                if (status == 'deleted') {
                    msg = 'Are you sure you want to delete selected record?';
                    msg2 = 'Records Deleted Successfully.';
                }if (status == 'active') {
                    msg = 'Are you sure you want to Activate selected record?';
                    msg2 = 'Records Activated Successfully.';
                }if (status == 'inactive') {

                    msg = 'Are you sure you want to inactive selected record?';
                    msg2 = 'Records inactivated Successfully.';
                }   

                Swal.fire({
                    title: 'Please Confirm',
                    text: msg,
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes',
                    cancelButtonText: 'No'
                })
                .then(result => {
                    if (result.value) {
                        return this.$store.dispatch(POST_API, {
                            data:{
                                id: this.selected,
                                status:status
                                },
                            api:"/api/change-multiple-issue-status",
                        })
                        .then(() => {
                            if (this.$store.getters.containsErrors) {
                                this.error_message = this.$store.getters.getErrors;
                                this.showAlert();
                            } else {
                                Swal.fire({
                                    icon: 'success',
                                    title: msg2,
                                    showConfirmButton: false,
                                    timer: 1500
                                })
                                this.selected = [];
                                this.selectAll = false;
                                this.filterTable();
                            }
                        });
                    }
                }).catch(err => {})
            } else {
                Swal.fire({
                    icon: 'warning',
                    title: 'Please select tasks.',
                    showConfirmButton: false,
                    timer: 1500
                })
            }
        },

        getZip(){
            var organization  = this.$store.getters.currentUser.organization;
            var keyword       = this.searchQuery;
            var sortBy        = this.sortBy;
            var sortDirection = this.isSortDirDesc;
            var site          = this.siteData ? this.siteData._id : null;
            var locations     = this.locations.length > 0 ? JSON.stringify(this.locations) : [];
            var frequency     = this.frequencyData ? this.frequencyData.name : '';
            var locations     = this.locations.length > 0 ? JSON.stringify(this.locations) : [];
            // var crew          = this.supervisor && this.supervisor.length > 0 ? this.supervisor : [];
            var crew          = this.crew_filter && this.crew_filter.length > 0 ? this.crew_filter : '';
            var start = this.start != null && this.start != '' ? moment(this.start).format('YYYY-MM-DD') : "";
            var end = this.end != null && this.end != '' ? moment(this.end).format('YYYY-MM-DD') : "";
            var report        = 'periodic';
            var role          = this.$store.getters.currentUser.role;
            var om_sites      = this.$store.getters.currentUser.om_sites;
            var group         = this.group && this.group.length > 0 ? this.group.toString() : [];
            var dateFilter    = 'sw';
            var assign_status = this.assign_status;

            var api    = '/api/get-zip-tasks?';
            var params = "organization="+organization+"&keyword="+keyword+"&sortBy="+sortBy+"&sortDirection="+sortDirection+
                    "&site="+site+"&frequency="+frequency+"&locations="+locations+"&crew="+crew+"&start="+start+
                    "&end="+end+"&report="+report+"&role="+role+"&om_sites="+om_sites+"&group="+group+"&dateFilter="+dateFilter+"&assign_status="+this.assign_status+"&user_id="+this.$store.getters.currentUser._id;

            var baseurl = "";
            baseurl = process.env.VUE_APP_SERVER_URL + api + params;

            window.open(baseurl,'_blank');
        },

        zipDownload(){
            return this.$store.dispatch(POST_API, {
                data:{
                    keyword: this.searchQuery,                 
                    sortBy:this.sortBy,
                    sortDirection:this.isSortDirDesc,
                    site:this.siteData ? this.siteData._id : null,
                    frequency:this.frequencyData ? this.frequencyData :null,
                    locations : this.locations,
                    supervisor: this.supervisor,
                    crew: this.crew_filter,
                    start:moment(this.start).format('YYYY-MM-DD'),
                    end:moment(this.end).format('YYYY-MM-DD'),
                    report:'periodic',
                    role:this.$store.getters.currentUser.role,
                    om_sites:this.$store.getters.currentUser.om_sites,
                    assign_status:this.assign_status
                },
                api:"/api/check-zip-tasks",
            })
            .then(() => {
                if (this.$store.getters.containsErrors) {
                    this.error_message = this.$store.getters.getErrors;
                    this.errorAlert();
                } else {
                    var data = this.$store.getters.getResults.data;
                    
                    if(data > 0){
                      this.getZip();
                    } else {
                      this.errorAlert();
                    }
                }
            });
        },

        openPdf(path){
            window.open(path+'?'+new Date(), '_blank');
        },

        reGenerateReport(id){
            return this.$store.dispatch(POST_API, {
                data:{ id:id },
                api: '/api/regenerate-task-report'
            })
            .then(() => {
                if (this.$store.getters.containsErrors) {
                    this.error_message = this.$store.getters.getErrors;
                    this.popshowDismissibleAlert = true;
                } else {
                    this.popshowDismissibleAlert = false;
                    
                    var data  = this.$store.getters.getResults.data;
                    
                    Swal.fire({
                        icon: 'success',
                        title: 'Report Regenerated Successfully.',
                        showConfirmButton: false,
                        timer: 1500
                    })
                    .then( () => {
                        window.open(data + '?' + new Date(), '_blank');
                        this.filterTable();  
                    })
                }
            });
        },

        openPDFPopUp(){
            this.popshowDismissibleAlert = false;
            this.pdf_type = 'single';
            this.$refs['pdf_export'].show();
        },

        validatePDF(e){
            this.download('pdf');
            this.$refs['pdf_export'].hide();
        },

        download(type){
            var keyword = this.searchQuery;
            var sortBy = this.sortBy;
            var sortDirection = this.isSortDirDesc;
            var site = this.siteData ? this.siteData._id : "";
            var site_name = this.siteData ? this.siteData.site_name : "";
            
            var frequency = this.frequencyData ? this.frequencyData : "";

            var locations  =  [];

            for (var i = 0; i < this.locations.length; i++) {
                locations.push(this.locations[i]._id);
            }

            var om_sites = [];

            for (var i = 0; i < this.$store.getters.currentUser.om_sites.length; i++) {
                om_sites.push(this.$store.getters.currentUser.om_sites[i]);
            }

            var role = this.$store.getters.currentUser.role;

            var crew =  this.crew;
            var start = this.start != null && this.start != '' ? moment(this.start).format('YYYY-MM-DD') : "";
            var end = this.end != null && this.end != '' ? moment(this.end).format('YYYY-MM-DD') : "";
            var status = 'closed' ;
            var assign_status = this.assign_status ;

            var params = "keyword="+keyword+"&sortBy="+sortBy+"&sortDirection="+sortDirection+"&site="+site+"&site_name="+site_name+
            "&frequency="+frequency+"&locations="+locations+"&crew="+crew+"&start="+start+
            "&end="+end+"&status="+status+"&organization="+ this.$store.getters.currentUser.organization+"&role="+role+
            "&om_sites="+om_sites.join(',')+"&report=periodic"+"&pdf_type="+this.pdf_type+"&assign_status="+this.assign_status+"&user_id="+this.$store.getters.currentUser._id;

            var baseurl = "";

            if (type == 'pdf') {
                baseurl = process.env.VUE_APP_SERVER_URL +"/api/task-status-pdf?"+params;
            }else{
                baseurl = process.env.VUE_APP_SERVER_URL +"/api/task-status-excel?"+params;
            }
            window.open(baseurl,'_blank');
        },

        getSiteGroup(){
            return this.$store.dispatch(POST_API, {
                data:{
                    site:this.siteData._id,
                },
                api: '/api/task-group-suggestions'
            })
            .then(() => {
                if (this.$store.getters.containsErrors) {
                    this.error_message = this.$store.getters.getErrors;
                    this.showDismissibleAlert = true;
                } else {
                    this.showDismissibleAlert = false;
                    var data  = this.$store.getters.getResults.data;
                    
                    this.groupArray = data;
                    return this.groupArray;
                }
            });
        },

        openModel(item){
            this.popshowDismissibleAlert = false;
            this.taskdate = moment.tz('Asia/Singapore').subtract(1,'days').format('YYYY-MM-DD');
            this.taskgroup = [];
            this.group_selected = 'selected';
            this.groupDateType = 'periodic';
            
            this.getSiteGroup();

            this.$refs['group_export'].show();
        },

        exportGroup(bvModalEvt){
            bvModalEvt.preventDefault()

            if (this.group_selected == 'all') {
                this.taskgroup = this.groupArray;
            }

            if(this.taskgroup.length == 0){
                this.error_message = 'Group(s) Not Found';
                this.popshowDismissibleAlert = true;
            } else {
                return this.$store.dispatch(POST_API, {
                    data:{
                        group:this.taskgroup,
                        taskdate:this.taskdate,
                        site:this.siteData._id,
                        dateFilter:this.groupDateType,
                    },
                    api: '/api/check-export-group-task'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                        this.popshowDismissibleAlert = true;
                    } else {
                        this.popshowDismissibleAlert = false;
                        var message  = this.$store.getters.getResults.message;
                        var data  = this.$store.getters.getResults.data;
                        
                        if (data > 0) {
                            this.popshowDismissibleAlert = false;
                            this.$refs['group_export'].hide();
                            this.getGroupZip();
                        }else{
                            this.error_message = 'No Records Found.';
                            this.popshowDismissibleAlert = true;
                        }
                    }
                });
            }
        },

        getGroupZip(){
            var group = this.taskgroup.toString();
            var taskdate = this.taskdate;
            var site = this.siteData._id;
            var dateFilter = this.groupDateType;

            var params = "group="+group+"&taskdate="+taskdate+"&site="+site+"&dateFilter="+dateFilter;

            var api = this.taskgroup.length > 1 ? '/api/export-group-task?' : '/api/export-group-task-single?';

            var baseurl = "";
            baseurl = process.env.VUE_APP_SERVER_URL + api + params;

            window.open(baseurl,'_blank');

            this.popshowDismissibleAlert = false;
            this.$refs['group_export'].hide();
        },
        openTimePopup(item){
            this.form = {
                id:item._id,
                actual_started:item.actual_started,
                actual_end: item.actual_end,
                closed_time:item.closed_time,
                unique_id:item.unique_id,
                start_date:item.start,
                plannedStartTime:item.plannedStartTime
            }

            this.popshowDismissibleAlert = false;
            
            

            this.$refs['modify_time'].show();
        },
        disabledDate(date){
           
            var time = moment(new Date(this.form.start_date + ' '+ this.form.plannedStartTime)).format('HH:mm');
            if (time == '00:00') {
                return date < moment(new Date(this.form.start_date+ ' '+ this.form.plannedStartTime)).tz('Asia/Singapore');
            }else{

                return date < moment(new Date(this.form.start_date+ ' '+ this.form.plannedStartTime)).subtract(1,'days').tz('Asia/Singapore');

            }
            return date < moment(new Date(this.form.start_date+ ' '+ this.form.plannedStartTime)).subtract(1,'days').tz('Asia/Singapore');
        },
        disabledTime(date){
            var time = moment(new Date(this.form.start_date + ' '+ this.form.plannedStartTime)).format('HH:mm');

            return date < moment(new Date(this.form.start_date+ ' '+ this.form.plannedStartTime)).tz('Asia/Singapore');

        },
        updateCompletionTime(e){
            e.preventDefault();

            return this.$store.dispatch(POST_API, {
                    data:{
                        item:this.form
                    },
                    api: '/api/update-completion-time'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                        this.popshowDismissibleAlert = true;
                    } else {
                        this.popshowDismissibleAlert = false;
                        var message  = this.$store.getters.getResults.message;
                        var data  = this.$store.getters.getResults.data;

                        Swal.fire({
                            icon: 'success',
                            title: 'Report Updated Successfully.',
                            showConfirmButton: false,
                            timer: 1500
                        })
                        .then( () => {
                            
                            this.$refs['modify_time'].hide();
                            this.filterTable();  
                        })
                        
                        
                    }
                });
        }

	},

    mounted(){
        // this.dataList();
        // this.getSiteGroup();
    }
  
}
</script>

<style lang="scss" scoped>
	.per-page-selector {
		width: 90px;
	}
	.wordBreak2{
		text-align: justify !important;
		text-justify: inter-word !important;
	}
</style>

<style lang="scss">
	@import '@core/scss/vue/libs/vue-select.scss';
</style>